@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.publishListingError {
  color: var(--colorFail);
  width: 100%;
  margin: 0 20px 10px 0;
  padding: 0;
  text-align: center;

  @media (--viewportMedium) {
    text-align: right;
  }
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.actionLinks {
  display: inline-flex;
  align-items: center;
}

.actionBarForProductLayout {
  width: 100%;

  @media (--viewportMedium) {
    margin: 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionBarContainerForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionBarForHeroLayout {
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 24px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}
.publishBtn {
  width: fit-content;
  min-height: fit-content;
  margin: 10px 20px;
  margin-left: 0;
  padding: 8px 10px;

  font-size: 14px;

  @media (--viewportMedium) {
    padding: 8px 28px;
  }
}
.addPayoutDetails,
.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 14px 24px 11px 24px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 24px 24px 24px 12px;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100vw;
    margin: 0 auto;
    padding: 0 16px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    padding: 0 16px;
    margin: 0 auto 117px;
    max-width: var(--contentMaxWidth);
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: 100%;
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 450px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 0;
    margin: 0 auto 0 auto;
  }

  @media (--viewportLarge) {
    max-width: unset;
    width: 100%;

    padding: 0;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 0px;

    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100vw - 478px);
    flex-basis: calc(100% - 478px);
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.orderColumnForHeroLayout {
  /* min-width so that add-on title can overflow ellipsis */

  min-width: 0;

  border-radius: 12px;
  height: fit-content;
  padding-bottom: 0px;

  @media (--viewportMedium) {
    padding-bottom: 30px;
  }

  @media (--viewportLarge) {
    display: block;
    margin-left: 20px;
    margin-top: 15px;

    padding: 0 30px;
    padding-top: 24px;
    padding-bottom: 30px;
    flex-basis: 450px;
    flex-shrink: 0;

    box-shadow: 0px 2px 20px -5px #6330bb21;
    background-color: var(--colorWhite);
  }

  label {
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;

    color: var(--colorDarkBlue);
  }
}

.field,
.artistName {
  margin: 15px 16px;

  @media (--viewportMedium) {
    margin: 15px 0;
  }
}

.artistName {
  margin-top: 0;
  font-size: 30px;

  @media (--viewportMedium) {
    font-size: 40px;
  }
}

.chipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: 20px 0;
}

.type,
.category {
  display: flex;
  gap: 4px;
  align-items: center;
}

.typeIcon,
.categoryIcon {
  padding: 6px 10px;
  border-radius: 50%;
  background-color: var(--marketplaceColorLight);

  svg {
    fill: var(--colorWhite);
  }
}

.numOfBandMembersTitle,
.timingTitle {
  display: flex;
  align-items: center;
  gap: 4px;
}

.numOfBandMembersTitle,
.timingTitle,
.descriptionTitle,
.addOnTitle {
  font-size: 21px;
  padding: 0;
  margin: 0;
  margin-bottom: 12px;
  line-height: normal;
}

.iconPeople,
.iconClock,
.iconNote,
.iconLocation {
  width: 20px;
  height: 20px;

  color: var(--colorGrey700);
}

.timesContainer {
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin-top: 20px;

  text-transform: capitalize;

  @media (--viewportXXSmall) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
    gap: 28px;
  }

  @media (--viewportLargeWithPaddings) {
    gap: 78px;
  }
}

.timesField {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: var(--colorDarkBlue);
}

.timesLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;

  text-wrap: wrap;
  color: var(--colorGrey600);
}

.setListTitle {
  display: block;
  text-align: center;
  margin: 0;
  margin-bottom: 40px;
  margin-top: 40px;
  padding: 0;

  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;

  color: var(--colorTeal);

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.setList {
  max-height: 80vh;
  overflow: auto;
  text-align: center;
  max-width: 100vh;
  overflow-wrap: break-word;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;

  @media (--viewportMedium) {
    max-height: 58vh;
  }
}

.addons {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 100%;
}

.addOnsContainer {
  display: flex;
  flex-wrap: wrap;

  gap: 18px;

  margin-top: 20px;
  justify-content: center;

  @media (--viewportSmall) {
    justify-content: flex-start;
  }
}

.addOnTitle {
  text-align: center !important;
  @media (--viewportXXSmall) {
    text-align: left;
  }
}

.addOnBtn {
  color: var(--colorGrey700);
  border: none;
  display: inline;
  width: fit-content;
  background-color: transparent;

  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    background-color: transparent;
    outline: none;
    box-shadow: none;

    cursor: pointer;
    transform: scale(1.1);
  }
}

.addOn {
  width: 143px;
}

.orderPanel {
  box-shadow: 0px 2px 20px -5px #6330bb21;
  background-color: var(--colorWhite);
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
  }
}

.orderPanelTitle {
  /* Font */

  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--marketplaceColor);

  /* Layout */
  width: 100%;
  margin: 40px 0;

  @media (--viewportLarge) {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 30px;
    line-height: 20px;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);
  font-size: 21px;

  margin-top: 0;
  margin-bottom: 12px;

  display: flex;
  align-items: center;
  gap: 4px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  text-align: center;
  color: var(--colorDarkBlue);

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.sectionAuthor {
  padding: 12px;

  background-color: var(--marketplaceColor);
  color: var(--colorWhite);

  @media (--viewportMedium) {
    padding: 12px 36px;
  }
}

.contactAuthor {
  margin: 0;
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 16px;
}

.disabled {
  background-color: var(--colorGrey300);
}

.contactBtn {
  margin: 0;
  margin-left: 12px;
  width: fit-content;
  padding: 12px 18px;

  text-wrap: nowrap;
  font-size: 14px;
  line-height: 16px;
  color: var(--colorWhite);
  border-color: var(--colorWhite);

  &:hover,
  &:focus {
    background-color: var(--marketplaceColorDark);
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0;

  @media (--viewportLarge) {
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.basicInfo {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 10px;

  flex-wrap: wrap;

  > * {
    flex: 1 1 174px;
  }
}

.icon {
  color: var(--marketplaceColor);
}

.cell {
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  box-shadow: 0px 2px 20px -5px #6330bb21;
  border-radius: 12px;
  background-color: var(--colorWhite);

  b {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;

    color: var(--colorDarkBlue);
  }
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  color: var(--colorDarkBlue);
}

.price {
  composes: cell;

  background: var(--colorLightBlue);
  width: 100%;

  @media (--viewportLarge) {
    width: 174px;
  }
}

.priceValue {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--colorTeal);
}

.container {
  max-width: calc(100vw - 32px);
  overflow-x: auto;
  box-shadow: 0px 2px 20px -5px #6330bb21;
  background-color: var(--colorWhite);
  border-radius: 12px;

  padding: 28px 30px;
  position: relative;
}

.subtitle {
  padding: 0;
  margin: 0;

  font-size: 18px;
  font-weight: 700;
  line-height: 19.52px;
  text-align: left;
  text-transform: uppercase;

  color: var(--colorTeal);
}

.expandBtn {
  position: absolute;
  right: 30px;
  top: 30px;

  svg {
    color: var(--marketplaceColor);
  }
}

.types {
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media (--viewportSmall) {
    flex-direction: row;
  }
}

.type {
  svg {
    height: 20px;
    width: 20px;
    flex-basis: 20px;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.type {
  background-color: var(--marketplacePale);

  border-radius: 23px;
  padding: 6px 12px;
  margin-top: 20px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--colorDarkBlue);

  width: fit-content;

  display: flex;
}

.categoriesWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 20px;
  overflow: hidden;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

.categoriesLimitedHeight {
  max-height: 36px;
}

.categoriesTitle {
  padding-right: 30px;
}

.wrapped {
  flex-wrap: wrap !important;
}

.category {
  background: rgba(245, 245, 245, 1);
  border-radius: 23px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--colorDarkBlue);
  text-wrap: wrap;

  padding: 6px 12px;
  @media (--viewportXXSmall) {
    text-wrap: nowrap;
  }
}

.overflowBtn {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  flex-basis: 100px;

  color: #a09ca7;
}

.hidden {
  visibility: hidden;
}

.invisible {
  display: none;
}

.description {
  pre {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    color: var(--colorGrey600);

    position: relative;
    white-space: pre-wrap; /* Preserves whitespace but allows wrapping */
    word-break: break-word; /* Breaks long words to fit the container */
    margin-bottom: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: var(--max-lines);
    -webkit-box-orient: vertical;
  }

  pre.expanded {
    /* Show full text */
    -webkit-line-clamp: unset;
  }
}

.setListField {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;

  padding: 20px 30px;

  @media (--viewportXXSmall) {
    flex-direction: row;
    align-items: center;
  }
}

.setListBtn {
  width: 182px;
  min-height: 41px;

  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  svg {
    color: var(--marketplaceColor);
  }

  &:hover,
  &:focus {
    svg {
      color: var(--colorWhite);
    }
  }
}

.addOnsHeader {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportXXSmall) {
    flex-direction: row;
  }
}

.viewAddonsBtn {
  font-size: 16px;
}

.modalTitle {
  composes: h3 from global;
  text-align: center;

  font-size: 28px;
  line-height: 30px;

  margin: 20px 0;
}

.faqBtn {
  min-width: 150px;
  margin: 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.faqModal {
  padding: 0 !important;
}

.faqModalContent {
  padding: var(--modalPadding);
  padding-bottom: 12px;

  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
    padding-bottom: 12px;
  }
}

.faqTitle {
  composes: h2 from global;
  text-align: center;
}

.marketplaceColored {
  color: var(--marketplaceColor);
}
.faqContainer {
  overflow: auto;
  max-height: 76vh;

  @media (--viewportXXSmall) {
    max-height: 80vh;
  }

  @media (--viewportMedium) {
    max-height: 60vh;
  }
}

.faq {
  border-bottom: 1px solid var(--colorGrey200);
  margin: 18px 0;
}

.question {
  text-align: center;
  font-weight: var(--fontWeightBold);
}

.answer {
  white-space: pre-wrap; /* Preserves whitespace but allows wrapping */
  word-break: break-word; /* Breaks long words to fit the container */
}

.cardHeader {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  margin: 20px 0 30px 0;
  overflow-x: auto;
}

.tab {
  padding: 12px 4px;

  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  color: var(--colorDarkBlue);
  transition: all 0.3s ease;
  text-align: start;

  &:hover {
    text-decoration: none;
  }
}

.tab-content {
  margin-top: 20px;
  font-size: 16px;
}

.tab.activeTab {
  color: var(--marketplaceColor);
  border-bottom: 3px solid var(--marketplaceColor);
}
